import React, {useEffect} from 'react';
import styled from 'styled-components';
import HubspotForm from 'react-hubspot-form'
import classNames from "classnames";
import {ProgressBar} from "primereact/progressbar";

export const HSFormTestId = '1580621d-c8da-4fb3-8c6d-1e64ae37b08b';

export type HSFormProps<FormData=any> = {
    className?: string;
    formId: string;
    onSubmit?: (data: FormData) => void;
};

export function HSForm<FormData=any>({formId, className, onSubmit}: HSFormProps<FormData>) {
    useEffect(() => {
        if (onSubmit) {
            const handler = (event) => {
                if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit' && event.data.id === formId) {
                    onSubmit?.(event.data.data as FormData);
                }
            }

            window.addEventListener('message', handler);
            return () => {
                window.removeEventListener('message', handler);
            }
        }
    }, [onSubmit, formId])

    return <Container>
        <HubspotForm
            className={classNames('form', className)}
            region='eu1'
            portalId='25011193'
            formId={formId}
            loading={<ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
        />
    </Container>
}

const Container = styled.div`
  width: 100%;
  &>* {
    width: 100%;
  }
`;
