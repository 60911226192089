import React from 'react';
import {BugReport} from "../../components/BugReport";
import {TwoToneText} from "../../components/TwoToneText";
import {PageState} from "../../containers/PageState";
import {Layout} from "../../containers/Layout";



export default function BugReportForm(props: PageState) {

    return <Layout {...props} meta={{title: 'Bug Report'}} >
        <h1><TwoToneText w1={'Bug'} w2={'Report'} reverse/></h1>
        <BugReport className='p-mb-4'/>
    </Layout>
}
