import React from 'react';
import styled from 'styled-components';

import {HSForm} from "./HSForm";
import {IntrinsicElementProps} from "../utils";

export type BugReportProps = IntrinsicElementProps;

export function BugReport({...props}: BugReportProps) {
    return <Container {...props}>
        <HSForm formId={'cdad8cc5-1f80-47bc-8e02-3f06720b4170'}/>
    </Container>;
}


const Container = styled.div`
  padding: 1em;
  width: 100%;
  max-width: 40em;  
`;
